import { useState, useEffect } from 'react';

const useAuthModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const shortcutHandler = (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === 'KeyU') {
        setIsModalOpen(true);
      }
    };

    document.addEventListener('keypress', shortcutHandler);

    return () => document.removeEventListener('keypress', shortcutHandler);
  }, []);

  return {
    isModalOpen,
    closeModal,
  };
};

export default useAuthModal;

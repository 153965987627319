import React from 'react';

import App from './src/components/App';
import CompendiumNavigation from './src/components/CompendiumNavigation';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};

export const wrapPageElement = ({ element, props }) => {
  if (
    !props.location.pathname.includes('/compendium') ||
    props.location.pathname.includes('/compendium/about')
  ) {
    return element;
  }

  return <CompendiumNavigation pathname={props.location.pathname}>{element}</CompendiumNavigation>;
};

import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

const AnimatedLink = ({ children, className, isDisabled }) => {
  if (isDisabled) {
    return <span className="leading-none">{children}</span>;
  }

  return (
    <motion.span
      className={cn(className, 'inline-block leading-none')}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.95 }}
      tabIndex="-1"
    >
      {children}
    </motion.span>
  );
};

export default AnimatedLink;

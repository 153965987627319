import authApi from './clients/auth';

export const signUp = async (payload) => {
  const { data } = await authApi.post('/auth/sign-up', payload);

  return data;
};

export const signIn = async (payload) => {
  const { data } = await authApi.post('/auth/sign-in', payload);

  return data;
};

export const refresh = async () => {
  const { data } = await authApi.get('/auth/refresh');

  return data;
};

export const signOut = async () => {
  const { data } = await authApi.get('/auth/sign-out');

  return data;
};

export const activate = async (activationToken) => {
  const { data } = await authApi.get(`/auth/activate/${activationToken}`);

  return data;
};

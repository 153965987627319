import React from 'react';
import { Link } from 'gatsby';
import { VscHome } from 'react-icons/vsc';

import AnimatedLink from '../AnimatedLink';

const HomeLink = ({ className }) => {
  return (
    <AnimatedLink className={className}>
      <Link to="/">
        <VscHome size="24" />
      </Link>
    </AnimatedLink>
  );
};

export default HomeLink;

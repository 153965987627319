import { useContext } from 'react';

import { ResponsiveContext } from '../context/Responsive';

const useResponsive = () => {
  const { isMobile, isMobileStructureOpen, setIsMobileStructureOpen } =
    useContext(ResponsiveContext);

  const toggleArticleScrolling = (value) => {
    const articleContainer = document.getElementById('article-container');

    articleContainer.style.overflow = value ? 'auto' : 'hidden';
  };

  const toggleMobileStructure = (value) => {
    if (!isMobile) {
      return;
    }

    toggleArticleScrolling(!value);
    setIsMobileStructureOpen(value);
  };

  return { isMobile, isMobileStructureOpen, toggleMobileStructure };
};

export default useResponsive;

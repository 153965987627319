import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';
import cn from 'classnames';

import Modal from '../Modal';
import useAuthModal from '../../hooks/useAuthModal';
import SignInForm from '../SignInForm';
import SignUpForm from '../SignUpForm';
import Button from '../Button';
import useAuth from '../../hooks/useAuth';
import { refresh, signOut } from '../../restApi/auth';
import './index.scss';

const Authentication = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoadingAuthentication, setIsLoadingAuthentication] = useState(false);
  const { user, deauthenticateUser, authenticateUser } = useAuth();
  const { isModalOpen, closeModal } = useAuthModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignOut = async () => {
    try {
      await signOut();

      deauthenticateUser();
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response?.data.message || error.message, { variant: 'error' });
    } finally {
      closeModal();
    }
  };

  const handleAuthentication = async () => {
    try {
      setIsLoadingAuthentication(true);

      const { accessToken, user } = await refresh();

      authenticateUser(accessToken, user);
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response?.data.message || error.message, { variant: 'error' });
    } finally {
      setIsLoadingAuthentication(false);
      closeModal();
    }
  };

  return (
    <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {!user ? (
            <>
              <div className="flex gap-4">
                {['Authenticate', 'Sign In', 'Sign Up'].map((label, i) => (
                  <button
                    key={label}
                    type="button"
                    className={cn({ underline: i === currentTab }, 'uppercase')}
                    onClick={() => setCurrentTab(i)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              {currentTab === 0 && (
                <Button
                  className="mt-8"
                  onClick={handleAuthentication}
                  isLoading={isLoadingAuthentication}
                >
                  Authenticate
                </Button>
              )}
              {currentTab === 1 && <SignInForm closeModal={closeModal} />}{' '}
              {currentTab === 2 && <SignUpForm closeModal={closeModal} />}
            </>
          ) : (
            <Button onClick={handleSignOut}>Sign Out</Button>
          )}
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default Authentication;

import axios from 'axios';

import { getApiUrl } from '../../utils/getApiUrl';

const authApi = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
});

export default authApi;

import React from 'react';
import { SnackbarProvider } from 'notistack';

const ToastProvider = ({ children }) => {
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;

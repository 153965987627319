import React from 'react';

import AuthProvider from '../../context/Auth';
import ToastProvider from '../../context/Toast';
import Authentication from '../Authentication';
import ResponsiveProvider from '../../context/Responsive';

const App = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <Authentication />
        <ResponsiveProvider>{children}</ResponsiveProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;

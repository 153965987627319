import { useContext, useCallback } from 'react';

import { AuthContext } from '../context/Auth';
import { setToken } from '../restApi/clients/private';

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const authenticateUser = useCallback(
    (accessToken, user) => {
      setToken(accessToken);
      setUser(user);
    },
    [setUser],
  );

  const deauthenticateUser = () => {
    setToken(null);
    setUser(null);
  };

  return {
    user,
    isAdmin: user?.roles.includes('Admin'),
    authenticateUser,
    deauthenticateUser,
  };
};

export default useAuth;

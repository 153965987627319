import React, { useState, useEffect, useMemo, createContext } from 'react';

export const ResponsiveContext = createContext();

const ResponsiveProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileStructureOpen, setIsMobileStructureOpen] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    // TODO: rewrite to resize observer API
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const value = useMemo(
    () => ({
      isMobile,
      isMobileStructureOpen,
      setIsMobileStructureOpen,
    }),
    [isMobile, isMobileStructureOpen],
  );

  return <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>;
};

export default ResponsiveProvider;

import axios from 'axios';

import { refresh } from '../auth';
import { getApiUrl } from '../../utils/getApiUrl';

let accessToken = null;

const privateApi = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
});

const onRequest = (config) => enrichConfigWithToken(config);

const onResponse = (response) => response;

const onResponseError = async (error) => {
  const originalRequest = error.config;

  if (error.response?.status !== 401) {
    throw error;
  }

  const { accessToken } = await refresh();

  setToken(accessToken);

  return axios.request(enrichConfigWithToken(originalRequest));
};

privateApi.interceptors.request.use(onRequest);
privateApi.interceptors.response.use(onResponse, onResponseError);

function enrichConfigWithToken(config) {
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
}

export function setToken(token) {
  accessToken = token;
}

export default privateApi;
